/* Featured Events Container */
.featured-events {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding-top: 0;
}

/* Swiper Container */
.swiper-container {
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    text-align: center;
}

/* Swiper Slide */
.swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
    /* Ensure the slides don't overlap */
    box-sizing: border-box;
    padding: 10px; /* Adds space between slides */
}

/* Custom arrow styles */
.swiper-button-prev,
.swiper-button-next {
    color: red;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 10px;
    border-radius: 50%;
}

.swiper-button-prev:hover,
.swiper-button-next:hover {
    transform: scale(1.2);
    transition: transform 0.2s ease;
}

/* Adjust arrow position */
.swiper-button-prev {
    left: 10px;
}

.swiper-button-next {
    right: 10px;
}

/* Change the color of the pagination dots */
.swiper-pagination-bullet {
    background-color: red;
}

/* Change the active bullet to a brighter red */
.swiper-pagination-bullet-active {
    background-color: #ff4d4d;
}

