html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  background-color: #000; /* Set your desired background color */
}

body {
  font-family: Arial, sans-serif; /* Choose a font you prefer */
  color: #fff;
}

/* Container styling */
.home-container {
  background-color: #000;
  color: #fff;
}

/* Banner Section */
.banner-image {
  width: 100%;
  min-height: 300px;
  max-height: 300px; /* Set height to auto if it's responsive */
  object-fit: cover;
  display: block; /* Ensures no gaps */
}

/* Profile Image Section */
.profile-image-container {
  position: absolute;
  top: 225px; /* Adjust overlap for a better position */
  left: 50%;
  transform: translateX(-50%);
  z-index: 2; /* Ensure it appears above everything */
}

.profile-image {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  object-fit: cover;
  border: 5px solid red;
}

/* Social Icons */
.social-icons {
  display: flex;
  justify-content: center;
  top: 125px; /* Space the icons below the profile image */
  padding-bottom: 100px;
  z-index: 1; /* Ensures it's below the profile image */
  position: relative; /* Make sure it’s placed correctly */
}

.social-icons a {
  color: white;
  margin: 0 10px;
  transition: color 0.3s;
}

.social-icons a:hover {
  color: red;
}

/* Event card styling */
.card {
  border: 2px solid #ff0000; /* Red border to match the theme */
  border-radius: 10px; /* Slight rounding for smoother look */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); /* Add a subtle shadow */
  overflow: hidden; /* Clip content if it overflows */
  margin: 10px;
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Add hover effect */
}

.card-body {
  background-color: #1a1a1a; /* Dark background to make text pop */
  padding: 20px; /* More padding for cleaner spacing */
  text-align: center; /* Center text inside the card */
}

.card-title {
  font-size: 1.5rem; /* Larger title font */
  margin-bottom: 10px;
  color: #ff0000; /* Bold red color for title */
  text-transform: uppercase; /* Uppercase for event title */
  letter-spacing: 1px; /* Slight letter spacing for style */
}

.card-text {
  font-size: 1.1rem; /* Slightly larger text for easier reading */
  color: #ccc; /* Softer color for details */
  margin-bottom: 20px;
}

.card-text strong {
  color: #fff; /* Highlight specific details */
}

.p-3 {
  padding: 15px; /* Better spacing for padding */
}

/* Promoters text */
.promoters-present {
  font-weight: bold;
  font-size: 1.1rem;
  color: #ffcc00; /* Stand out color for promoters */
}

/* Button styles */
.btn-primary {
  background-color: #007bff;
  border-color: #007bff;
}

.btn-primary:hover {
  background-color: #0056b3;
}

.btn-warning {
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-warning:hover {
  background-color: #e0a800;
}

.btn-success {
  background-color: #28a745; /* Green for ticket button */
  border-color: #28a745;
}

.btn-success:hover {
  background-color: #218838;
}



/* Space between cards */
.col-md-6.mb-4 {
  margin-bottom: 30px;
}


/* Text Styling */
.text-danger {
  color: #ff0000 !important;
}

.form-control, .form-check-label {
  position: relative;
  z-index: 2; /* Ensure it's above other elements */
}

/* Edit Mode Toggle */
.edit-toggle-container {
  display: flex;
  justify-content: center;
  margin-top: 70px; /* Adjust margin to position the toggle lower */
  padding-top: 50px; /* Add padding for extra spacing from the social icons */
  position: relative; /* Keep it in flow with the rest of the content */
}

.edit-toggle-container label {
  margin-right: 10px;
  font-size: 16px;
  color: white;
}

/* Make sure that the events container allows cards to be displayed properly */
.upcoming-events .row {
  display: flex;
  justify-content: center; /* Align items in the center horizontally */
  flex-wrap: wrap; /* Allow cards to wrap to the next row */
}

.col-md-4 {
  flex: 1 1 300px; /* Allow each card to grow/shrink and set a minimum width */
  max-width: 100%; /* Let the cards be responsive */
  margin-bottom: 20px; /* Add spacing between rows */
}

.add-event-btn {
  display: inline-block;
  margin-left: auto;
  margin-right: 0;
  background-color: #28a745;
  border: none;
  color: white;
  padding: 8px 16px;
  text-align: center;
  text-decoration: none;
  font-size: 14px;
  border-radius: 5px;
  cursor: pointer;
}

.add-event-btn:hover {
  background-color: #218838;
}

.add-event-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

h2.text-danger {
  margin-right: 10px; /* Add a bit of space between the title and the button */
}

.add-event-btn .icon {
  margin-right: 8px; /* Space between icon and text */
}

.featured-events-title {
  text-align: center;
  margin-bottom: 10px;;
  font-size: 35px;
}