/* EventPage.css */

.event-page-container {
    background-color: black; /* Set the page background to black */
    min-height: 100vh;
    padding: 50px 0;
    color: white; /* Set the text color to white */
}

.event-page-content {
    background-color: #1c1c1c; /* A slightly lighter shade for the content */
    padding: 20px;
    border-radius: 10px;
}

.flyer-img {
    max-width: 100%;
    border: 4px solid red; /* Add a red border around the flyer */
    box-shadow: 0 4px 8px rgba(255, 0, 0, 0.5); /* Add some shadow to the flyer */
}

.event-title {
    color: red;
}

.map-embed {
    width: 100%;
    height: 0;
    padding-bottom: 56.25%; /* Aspect ratio 16:9 */
    position: relative;
    border: 1px solid white; /* To match the border style */
    margin-bottom: 20px;
  }
  
  .map-embed iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; /* Remove the iframe default border */
  }

.artist-card {
    background-color: #282828;
    border: none;
}

.artist-card img {
    border-radius: 10px;
}

.artist-card .card-title {
    color: red;
}